<template>
  <div class="readme-article">
    <h1 id="后台使用">二、后台使用</h1>
    <h2 id="首页">1.首页</h2>
    <h3 id="欢迎">1.1欢迎界面</h3>
    <p>
      平台统计数据展示，包括商品总量、名片总量、文章总量等。
    </p>
    <p>
      <img src="@/assets/img/card/1-1.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Card1-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>